import { createMuiTheme } from '@material-ui/core/styles';

const createTheme = (mainColor, luminance = 0.5) =>
  createMuiTheme({
    palette: {
      primary: {
        main: mainColor || '#ff5555',
        contrastText: luminance < 0.5 ? '#fff' : '#000'
      }
    },
    overrides: {
      MuiSelect: {
        select: {
          '&:focus': {
            backgroundColor: 'transparent'
          }
        }
      }
    }
  });

export default createTheme;
